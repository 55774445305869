import React from 'react';

const Experience = () => {
    return (
        <div name='experience' className='w-full h-screen bg-[#0a192f] text-gray-300'>
        <div className='flex flex-col justify-center items-center w-full h-full'>
          <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
            <div className='sm:text-right pb-8 pl-4'>
              <p className='text-4xl font-bold inline border-b-4 border-pink-600'>
                Relevant Experience
              </p>
            </div>
            </div>
            <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
              <div className='text-right text-4xl font-bold'>
                <p>Please take a look at my relevant experience</p>
              </div>
              <div>
                <h2 className='font-bold'>Centene Co. Oct 2020 - Present</h2>
                <p>Work as a full stack developer. Responsibilities include being involved in various phases of Software Development Life Cycle (SDLC) as requirement gathering, planning, analysis, design, development, testing, implementation, and maintenance. Designed and developed application utilizing Spring Boot, Spring MVC, Spring IOC, Spring Transactions, Spring JDBC.</p>
                <br/>
                <h2 className='font-bold'>Texas Capital Bank July 2018 - September 2020</h2>
                <p>Responsibilities included designing and developing web applications using React, Redux, and Material-UI for the front-end, and Spring Boot, Hibernate, and PostgreSQL for the back end.
                </p>
                <br/>
                <h2 className='font-bold'>Prime America Nepal INC Feb 2018 - April 2018</h2>
                <p>Developed a website so customers can browse through and purchase authentic Nepali products hand made by Nepali Artisans.</p>    
              </div>
            </div>
        </div>
      </div>
    );
}

export default Experience;
